<template>
  <div class="guide">
    <van-sticky>
      <div class="header">
        <van-icon class="back" name="arrow-left" size="25" color="white" @click="$router.go(-1)" />
        <div class="title">防骗指南</div>
      </div>
    </van-sticky>
    <div class="main">
      <div class="box">
        <div class="main-title"></div>
        <div class="main-content">
          <p>1、解锁用户谨记见面付款，务必使用平台支付预约金，私下交易被骗，与平台无关</p>
          <p>2、预约金可抵扣服务费，尾款到房间见面付！！！</p>
          <p>3、以转帐备注不对，要求重新转账，说见面退款的，一定不要转账，是骗子无疑</p>
          <p>4、以新客户第一次来怕你偷拍、怕你虐待妹子，让交保证金；以妹子到楼下了，先给钱后上楼，都是骗子，切勿相信，切勿转账！！！</p>
          <p>5、搜索QQ后只点击查找人添加，底下群千万别加，都是骗子</p>
          <p>6、上门给钱后不允许妹子以任何理由离开，尤其是趁你洗澡的时候</p>
          <p>7、总之一句话，切勿提前转账，见面付款！！！</p>
        </div>
        <div class="img-box">
          <div class="img"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'guide'
}
</script>

<style scoped lang="scss">
  .guide {
    .header {
      height: 44px;
      width: 100%;
      background-color: rgb(242,19,19);
      color: white;
      font-size: 20px;
      text-align: center;
      line-height: 44px;
      .back {
        position: absolute;
        left: 14px;
        top: 10px;
      }
    }
    .main {
      padding: 14px 16px 40px 16px;
      .box {
        color: red;
        height: 100%;
        width: 100%;
        border-radius: 15px;
        box-shadow: 0px 0px 3px 0px rgba(112,112,112, 0.2);
        .main-title {
          height: 90px;
          width: 100%;
          background: url("../../assets/png/guide-text.png") center center;
          background-repeat: no-repeat;
          background-size: 172px 43px;
        }
        .main-content {
          text-align: left;
          font-size: 16px;
          padding-left: 10px;
          padding-right: 5px;
          p {
            line-height: 25px;
            margin-bottom: 10px;
          }
        }
      }
    }
    .img-box {
      padding: 10px 10px 20px 0;
      display: flex;
      justify-content: flex-end;
      .img {
        height: 140px;
        width: 145px;
        background: url("../../assets/png/teacher.jpg") center center;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }
  .all-content {
    overflow: auto;
  }
</style>
